fcViews.month = MonthView;

function MonthView(element, calendar) {
	var t = this;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicView.call(t, element, calendar, 'month');

	function incrementDate(date, delta) {
		return date.clone().stripTime().add(delta, 'months').startOf('month');
	}

	function render(date) {
		t.intervalStart = date.clone().stripTime().startOf('month');
		t.intervalEnd = t.intervalStart.clone().add(1, 'months');

		t.start = t.intervalStart.clone();
		t.start = t.skipHiddenDays(t.start); // move past the first week if no visible days
		t.start.startOf('week');
		t.start = t.skipHiddenDays(t.start); // move past the first invisible days of the week

		t.end = t.intervalEnd.clone();
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last week if no visible days
		t.end.add((7 - t.end.weekday()) % 7, 'days'); // move to end of week if not already
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last invisible days of the week

		var rowCnt = Math.ceil(
			// need to ceil in case there are hidden days
			t.end.diff(t.start, 'weeks', true) // returnfloat=true
		);
		if (t.opt('weekMode') == 'fixed') {
			t.end.add(6 - rowCnt, 'weeks');
			rowCnt = 6;
		}

		t.title = calendar.formatDate(t.intervalStart, t.opt('titleFormat'));

		t.renderBasic(rowCnt, t.getCellsPerWeek(), true);
	}
}

fcViews.monthFluid = MonthFluidView;

function MonthFluidView(element, calendar) {
	var t = this;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	//Set fluid months to true (we are forcing this here);
	calendar.options.fluidMonths = true;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicView.call(t, element, calendar, 'monthFluid');

	function incrementDate(date, delta) {
		return date.clone().stripTime().add(delta, 'months').startOf('month');
	}

	function render(date) {
		t.intervalStart = date.clone().stripTime().startOf('month');
		t.intervalEnd = t.intervalStart.clone().add(1, 'months');

		t.start = t.intervalStart.clone();
		t.start = t.skipHiddenDays(t.start); // move past the first week if no visible days
		t.start.startOf('week');
		t.start = t.skipHiddenDays(t.start); // move past the first invisible days of the week

		t.end = t.intervalEnd.clone();
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last week if no visible days
		t.end.add((7 - t.end.weekday()) % 7, 'days'); // move to end of week if not already
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last invisible days of the week

		var rowCnt = Math.ceil(
			// need to ceil in case there are hidden days
			t.end.diff(t.start, 'weeks', true) // returnfloat=true
		);
		if (t.opt('weekMode') == 'fixed') {
			t.end.add(6 - rowCnt, 'weeks');
			rowCnt = 6;
		}

		t.title = calendar.formatDate(t.intervalStart, t.opt('titleFormat'));

		t.renderBasic(rowCnt, t.getCellsPerWeek(), true);
	}
}

fcViews.monthConstrain = MonthConstrainView;

function MonthConstrainView(element, calendar) {
	var t = this;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	//Set fluid months to false (we are forcing this here);
	calendar.options.fluidMonths = false;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicView.call(t, element, calendar, 'monthConstrain');

	function incrementDate(date, delta) {
		return date.clone().stripTime().add(delta, 'months').startOf('month');
	}

	function render(date) {
		t.intervalStart = date.clone().stripTime().startOf('month');
		t.intervalEnd = t.intervalStart.clone().add(1, 'months');

		t.start = t.intervalStart.clone();
		t.start = t.skipHiddenDays(t.start); // move past the first week if no visible days
		t.start.startOf('week');
		t.start = t.skipHiddenDays(t.start); // move past the first invisible days of the week

		t.end = t.intervalEnd.clone();
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last week if no visible days
		t.end.add((7 - t.end.weekday()) % 7, 'days'); // move to end of week if not already
		t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last invisible days of the week

		var rowCnt = Math.ceil(
			// need to ceil in case there are hidden days
			t.end.diff(t.start, 'weeks', true) // returnfloat=true
		);
		if (t.opt('weekMode') == 'fixed') {
			t.end.add(6 - rowCnt, 'weeks');
			rowCnt = 6;
		}

		t.title = calendar.formatDate(t.intervalStart, t.opt('titleFormat'));

		t.renderBasic(rowCnt, t.getCellsPerWeek(), true);
	}
}

fcViews.basicWeek = BasicWeekView;

function BasicWeekView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var weekCount = calendar.options.weekCount || 1;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicView.call(t, element, calendar, 'basicWeek');

	function incrementDate(date, delta, alt) {
		return date
			.clone()
			.stripTime()
			.add(delta * (alt ? 1 : weekCount), 'weeks')
			.startOf('week');
	}

	function render(date) {
		t.intervalStart = date.clone().stripTime().startOf('week');
		t.intervalEnd = t.intervalStart.clone().add(weekCount, 'weeks');

		t.start = t.skipHiddenDays(t.intervalStart);
		t.end = t.skipHiddenDays(t.intervalEnd, -1, true);

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			t.opt('titleFormat'),
			' \u2014 ' // emphasized dash
		);

		t.renderBasic(1, t.getCellsPerWeek() * weekCount, false);
	}
}

fcViews.basicDay = BasicDayView;

function BasicDayView(element, calendar) {
	// TODO: make a DayView mixin
	var t = this;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicView.call(t, element, calendar, 'basicDay');

	function incrementDate(date, delta) {
		var out = date.clone().stripTime().add(delta, 'days');
		out = t.skipHiddenDays(out, delta < 0 ? -1 : 1);
		return out;
	}

	function render(date) {
		t.start = t.intervalStart = date.clone().stripTime();
		t.end = t.intervalEnd = t.start.clone().add(1, 'days');

		t.title = calendar.formatDate(t.start, t.opt('titleFormat'));

		t.renderBasic(1, 1, false);
	}
}

fcViews.basicResourceVert = BasicResourceVertView;

function BasicResourceVertView(element, calendar) {
	var t = this;
	var resourceDays;

	t.hasTimePaging = false;
	t.hasResourcePaging = true;

	t.startOverride = null;

	//Customized for SeedCode
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicResourceView.call(t, element, calendar, 'basicResourceVert');

	resourceDays = t.opt('resourceDays');

	function incrementDate(date, delta, alt) {
		var incrementDays = alt ? 1 : resourceDays;
		var out = date
			.clone()
			.stripTime()
			.add(delta * incrementDays, 'days');
		out = t.resourceSkipHiddenDays(
			date.clone().stripTime(),
			out,
			delta,
			true
		);
		return out;
	}

	//This is what is initially called from global render script
	function render(date) {
		var titleFormat =
			resourceDays > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];

		t.intervalStart = date.clone().stripTime();
		t.intervalEnd = t.intervalStart.clone().add(resourceDays, 'days');
		t.start = t.skipHiddenDays(t.intervalStart);
		t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);
		t.renderOnIntervalStart = true;

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		t.renderBasic(1, calendar.fetchResources().length, false);
	}
}

fcViews.agendaWeek = AgendaWeekView;

function AgendaWeekView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var weekCount = calendar.options.weekCount || 1;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	AgendaView.call(t, element, calendar, 'agendaWeek');

	function incrementDate(date, delta, alt) {
		console.log('alt', alt);
		return date
			.clone()
			.stripTime()
			.add(delta * (alt ? 1 : weekCount), 'weeks')
			.startOf('week');
	}

	function render(date) {
		t.intervalStart = date.clone().stripTime().startOf('week');
		t.intervalEnd = t.intervalStart.clone().add(weekCount, 'weeks');

		t.start = t.skipHiddenDays(t.intervalStart);
		t.end = t.skipHiddenDays(t.intervalEnd, -1, true);

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			t.opt('titleFormat'),
			' \u2014 ' // emphasized dash
		);

		t.renderAgenda(t.getCellsPerWeek() * weekCount);
	}
}

fcViews.agendaDay = AgendaDayView;

function AgendaDayView(element, calendar) {
	// TODO: make a DayView mixin
	var t = this;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	AgendaView.call(t, element, calendar, 'agendaDay');

	function incrementDate(date, delta, alt) {
		var out = date.clone().stripTime().add(delta, 'days');
		out = t.skipHiddenDays(out, delta < 0 ? -1 : 1);
		return out;
	}

	function render(date) {
		t.start = t.intervalStart = date.clone().stripTime();
		t.end = t.intervalEnd = t.start.clone().add(1, 'days');

		t.title = calendar.formatDate(t.start, t.opt('titleFormat'));

		t.renderAgenda(1);
	}
}

fcViews.agendaResourceVert = AgendaResourceVertView;

function AgendaResourceVertView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var resourceDays;

	t.hasTimePaging = false;
	t.hasResourcePaging = true;

	t.startOverride = null;

	//Customized for SeedCode
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	AgendaResourceView.call(t, element, calendar, 'agendaResourceVert');

	resourceDays = t.opt('resourceDays');

	function incrementDate(date, delta, alt) {
		var incrementDays = alt ? 1 : resourceDays;
		var out = date
			.clone()
			.stripTime()
			.add(delta * incrementDays, 'days');
		out = t.resourceSkipHiddenDays(
			date.clone().stripTime(),
			out,
			delta,
			true
		);
		return out;
	}

	//This is what is initially called from global render script
	function render(date) {
		var titleFormat =
			resourceDays > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];
		t.intervalStart = date.clone().stripTime();
		t.intervalEnd = t.intervalStart.clone().add(resourceDays, 'days');
		t.start = t.skipHiddenDays(t.intervalStart);
		t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);
		t.renderOnIntervalStart = true;

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		t.renderAgenda();
	}
}

//Basic Grid View
fcViews.basicResourceHor = BasicResourceHorView;

function BasicResourceHorView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var resourceDays;
	var resources;

	t.hasTimePaging = false;
	t.hasResourcePaging = true;

	t.startOverride = null;

	//Customized for SeedCode
	//ToDo: we can probably remove this once the view is completed
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicGridView.call(t, element, calendar, 'basicResourceHor');

	resourceDays = Number(t.opt('resourceDays'));

	const clusterOptions = fc.getClusterDefinitions('basicResourceHor');

	function incrementDate(date, delta, alt) {
		var incrementDays = alt ? 1 : clusterOptions.duration;
		var out = date
			.clone()
			.stripTime()
			.add(
				delta * (incrementDays * clusterOptions.typeOffset),
				clusterOptions.type
			);
		if (clusterOptions.type === 'day') {
			out = t.resourceSkipHiddenDays(
				date.clone().stripTime(),
				out,
				delta,
				true
			);
		}
		return out;
	}

	//This is what is initially called from global render script
	function render(date) {
		let startResult;
		const titleFormat =
			resourceDays > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];
		t.renderOnIntervalStart = true;

		resources = calendar.fetchResources(false);

		if (clusterOptions.type !== 'day') {
			t.intervalStart = date
				.clone()
				.stripTime()
				.startOf(clusterOptions.type);
			t.intervalEnd = t.intervalStart
				.clone()
				.add(
					clusterOptions.duration * clusterOptions.typeOffset,
					clusterOptions.type
				);

			if (clusterOptions.typeOffset) {
				t.intervalStart.year(
					Math.floor(
						t.intervalStart.year() / clusterOptions.typeOffset
					) * clusterOptions.typeOffset
				);
				t.intervalEnd.year(
					Math.floor(
						t.intervalEnd.year() / clusterOptions.typeOffset
					) * clusterOptions.typeOffset
				);
			}

			// t.start = date.clone().stripTime();
			t.start = t.intervalStart.clone();
			t.end = t.intervalEnd.clone();
			t.startOverride = date.clone().stripTime();

			startResult = t.startOverride;
		} else {
			t.intervalStart = date.clone().stripTime();
			t.intervalEnd = t.intervalStart
				.clone()
				.add(clusterOptions.duration, 'days');
			t.start = t.skipHiddenDays(t.intervalStart);
			t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);

			startResult = t.start;
		}

		t.title = calendar.formatRange(
			startResult,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		t.renderBasic(
			resources.length,
			resourceDays,
			false,
			clusterOptions.type !== 'day' ? clusterOptions : null
		);
	}
}

//Agenda Grid View
fcViews.agendaResourceHor = AgendaResourceHorView;

function AgendaResourceHorView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var resources;

	t.hasTimePaging = true;
	t.hasResourcePaging = true;

	t.startOverride = null;

	//Customized for SeedCode
	//ToDo: we can probably remove this once the view is completed
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	AgendaGridView.call(t, element, calendar, 'agendaResourceHor');

	function incrementDate(date, delta, alt) {
		var out = date.clone().stripTime().add(delta, 'days');
		out = t.resourceSkipHiddenDays(
			date.clone().stripTime(),
			out,
			delta,
			true
		);
		return out;
	}

	//This is what is initially called from global render script
	function render(date) {
		var titleFormat = t.opt('titleFormat')[0];
		const gridStartTime = t.opt('gridTimes')[0];
		const gridEndTime = t.opt('gridTimes').slice(-1)[0];
		t.intervalStart = date.clone().stripTime();
		t.intervalEnd = t.intervalStart.clone().add(1, 'day');
		t.start = t.skipHiddenDays(t.intervalStart);
		t.end = t.skipHiddenDays(t.intervalEnd);
		t.renderOnIntervalStart = true;

		t.startTime = [
			gridStartTime.hours(),
			gridStartTime.minutes(),
			gridStartTime.seconds(),
		];
		t.endTime = [
			gridEndTime.hours(),
			gridEndTime.minutes(),
			gridEndTime.seconds(),
		];

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		resources = calendar.fetchResources(false);
		t.renderAgenda(resources.length, t.opt('gridTimes').length, false);
	}
}

fcViews.basicHorizon = HorizonView;

function HorizonView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var horizonSlider;
	var resources;

	t.hasTimePaging = false;
	t.hasResourcePaging = false;

	t.startOverride = null;

	//Customized for SeedCode
	//ToDo: we can probably remove this once the view is completed
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	basicHorizonView.call(t, element, calendar, 'basicHorizon');

	horizonSlider = Number(t.opt('horizonSlider'));

	resources = calendar.fetchResources(false);

	function incrementDate(date, delta, alt) {
		// Delta is the direction -1 or 1.
		// Alt is if an alternate increment is selected. Can be used for nudging the date range
		var clusterOptions = horizonSliderDefinitions(horizonSlider);

		var incrementDays = alt ? 1 : clusterOptions.duration;
		var out = date
			.clone()
			.stripTime()
			.add(
				delta * (incrementDays * clusterOptions.typeOffset),
				clusterOptions.type
			);
		if (clusterOptions.type === 'day') {
			out = t.resourceSkipHiddenDays(
				date.clone().stripTime(),
				out,
				delta,
				true
			);
		}
		return out;
	}

	//This is what is initially called from global render script
	function render(date) {
		//Clustering
		var clusterOptions = horizonSliderDefinitions(horizonSlider);

		var titleFormat;
		var startResult;

		var startTitleFormat =
			clusterOptions.duration > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];
		if (clusterOptions.type === 'month') {
			titleFormat = t.opt('titleFormat')[2];
		} else if (clusterOptions.type === 'year') {
			titleFormat = t.opt('titleFormat')[3];
		} else {
			titleFormat =
				clusterOptions.duration > 1
					? t.opt('titleFormat')[1]
					: t.opt('titleFormat')[0];
		}

		t.renderOnIntervalStart = true;

		if (clusterOptions.type !== 'day') {
			clusterOptions.columnCount = clusterOptions.duration; //Math.ceil(t.intervalEnd.diff(t.intervalStart, clusterType, true) / clusterTypeOffset)
			t.intervalStart = date
				.clone()
				.stripTime()
				.startOf(clusterOptions.type);
			t.intervalEnd = t.intervalStart
				.clone()
				.add(
					clusterOptions.duration * clusterOptions.typeOffset,
					clusterOptions.type
				);

			if (clusterOptions.typeOffset) {
				t.intervalStart.year(
					Math.floor(
						t.intervalStart.year() / clusterOptions.typeOffset
					) * clusterOptions.typeOffset
				);
				t.intervalEnd.year(
					Math.floor(
						t.intervalEnd.year() / clusterOptions.typeOffset
					) * clusterOptions.typeOffset
				);
			}

			// t.start = date.clone().stripTime();
			t.start = t.intervalStart.clone();
			t.end = t.intervalEnd.clone();
			t.startOverride = date.clone().stripTime();

			startResult = t.startOverride;
		} else {
			t.intervalStart = date.clone().stripTime();
			t.intervalEnd = t.intervalStart
				.clone()
				.add(clusterOptions.duration, 'days');
			t.start = t.skipHiddenDays(t.intervalStart);
			t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);

			startResult = t.start;
		}

		if (clusterOptions.type === 'month' || clusterOptions.type === 'year') {
			t.title =
				startResult.clone().format(startTitleFormat) +
				' - ' +
				t.end.clone().subtract(1).format(titleFormat);
		} else {
			t.title = calendar.formatRange(
				startResult,
				t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
				titleFormat,
				' \u2014 ' // emphasized dash
			);
		}
		t.renderBasic(
			resources.length,
			clusterOptions.duration,
			false,
			clusterOptions.type !== 'day' ? clusterOptions : null
		);
	}
}

//Basic Resource Days View
fcViews.basicResourceDays = BasicResourceDaysView;

function BasicResourceDaysView(element, calendar) {
	// TODO: do a WeekView mixin
	var t = this;
	var snapToMonth;
	var resourceDays;
	var resources;

	t.hasTimePaging = false;
	t.hasResourcePaging = true;

	t.startOverride = null;

	//Customized for SeedCode
	//ToDo: we can probably remove this once the view is completed
	function getResourcesPerView(resourceCount) {
		return Math.max(resourceCount, 1);
	}

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	BasicDaysView.call(t, element, calendar, 'basicResourceDays');

	resourceDays = Number(t.opt('resourceDays'));
	snapToMonth = t.opt('snapToMonth');

	function incrementDate(date, delta, alt) {
		if (snapToMonth) {
			return incrementMonthDate(date, delta);
		} else {
			return incrementDayDate(date, delta, alt);
		}
	}

	function incrementDayDate(date, delta, alt) {
		var incrementDays = alt ? 1 : resourceDays;
		var out = date
			.clone()
			.stripTime()
			.add(delta * incrementDays, 'days');
		out = t.resourceSkipHiddenDays(
			date.clone().stripTime(),
			out,
			delta,
			true
		);
		return out;
	}

	function incrementMonthDate(date, delta) {
		return date.clone().stripTime().add(delta, 'months').startOf('month');
	}

	//This is what is initially called from global render script
	function render(date) {
		var titleFormat =
			resourceDays > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];
		var rowCnt;

		if (snapToMonth) {
			t.intervalStart = date.clone().stripTime().startOf('month');
			t.intervalEnd = t.intervalStart.clone().add(1, 'months');

			t.start = t.intervalStart.clone();
			// 			// t.start = t.skipHiddenDays(t.start); // move past the first week if no visible days
			// 			// t.start.startOf('week');
			// 			// t.start = t.skipHiddenDays(t.start); // move past the first invisible days of the week

			t.end = t.intervalEnd.clone();
			// 			// t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last week if no visible days
			// 			// t.end.add((7 - t.end.weekday()) % 7, 'days'); // move to end of week if not already
			// 			// t.end = t.skipHiddenDays(t.end, -1, true); // move in from the last invisible days of the week

			rowCnt =
				t.end.diff(t.start, 'days') -
				t.getHiddenDayOffset(t.start, t.end, false);
		} else {
			t.intervalStart = date.clone().stripTime();
			t.intervalEnd = t.intervalStart.clone().add(resourceDays, 'days');

			t.start = t.skipHiddenDays(t.intervalStart);
			t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);

			rowCnt = resourceDays;
		}

		// t.start = t.intervalStart.clone();
		// t.end = t.intervalEnd.clone();
		t.renderOnIntervalStart = true;

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		resources = calendar.fetchResources(false);
		t.renderBasic(rowCnt, resources.length, false);
	}
}
// Agenda days view
fcViews.agendaDays = AgendaDaysView;
function AgendaDaysView(element, calendar) {
	var t = this;
	var snapToMonth;
	var resourceDays;
	var resources;

	t.hasTimePaging = true;
	t.hasResourcePaging = false;

	t.startOverride = null;

	//Customized for SeedCode

	// exports
	t.incrementDate = incrementDate;
	t.render = render;

	// imports
	AgendaDaysHorView.call(t, element, calendar, 'agendaDays');
	resourceDays = t.opt('resourceDays');
	snapToMonth = t.opt('snapToMonth');

	function incrementDate(date, delta, alt) {
		if (snapToMonth) {
			return incrementMonthDate(date, delta);
		} else {
			return incrementDayDate(date, delta, alt);
		}
	}

	function incrementDayDate(date, delta, alt) {
		var incrementDays = alt ? 1 : resourceDays;
		var out = date
			.clone()
			.stripTime()
			.add(delta * incrementDays, 'days');
		out = t.resourceSkipHiddenDays(
			date.clone().stripTime(),
			out,
			delta,
			true
		);
		return out;
	}

	function incrementMonthDate(date, delta) {
		return date.clone().stripTime().add(delta, 'months').startOf('month');
	}

	//This is what is initially called from global render script
	function render(date) {
		const gridStartTime = t.opt('gridTimes')[0];
		const gridEndTime = t.opt('gridTimes').slice(-1)[0];
		var titleFormat =
			resourceDays > 1
				? t.opt('titleFormat')[1]
				: t.opt('titleFormat')[0];
		var rowCnt;

		t.startTime = [
			gridStartTime.hours(),
			gridStartTime.minutes(),
			gridStartTime.seconds(),
		];
		t.endTime = [
			gridEndTime.hours(),
			gridEndTime.minutes(),
			gridEndTime.seconds(),
		];

		if (snapToMonth) {
			t.intervalStart = date.clone().stripTime().startOf('month');
			t.intervalEnd = t.intervalStart.clone().add(1, 'months');

			t.start = t.intervalStart.clone();
			t.end = t.intervalEnd.clone();

			rowCnt =
				t.end.diff(t.start, 'days') -
				t.getHiddenDayOffset(t.start, t.end, false);
		} else {
			t.intervalStart = date.clone().stripTime();
			t.intervalEnd = t.intervalStart.clone().add(resourceDays, 'days');

			t.start = t.skipHiddenDays(t.intervalStart);
			t.end = t.resourceSkipHiddenDays(t.intervalStart, t.intervalEnd);

			rowCnt = resourceDays;
		}

		t.renderOnIntervalStart = true;

		t.title = calendar.formatRange(
			t.start,
			t.end.clone().subtract(1), // make inclusive by subtracting 1 ms
			titleFormat,
			' \u2014 ' // emphasized dash
		);

		t.renderAgenda(rowCnt, t.opt('gridTimes').length, false);
	}
}
